<template>
  <div>
    <vue-final-modal v-model="isModalOpen" @keydown.esc="ModalClose" tabindex="0">
      <Modal_Segment_Audience_profile :profileInfo="$store.state.Audience.isProfileData" :isActiveProfileInfo="isActiveProfileInfo" @ModalClose="ModalClose" />
    </vue-final-modal>
    <WrapperSegmentProfiles @Filter-wrapper="onFilterWrapper" />
    <div>
      <TransitionFade>
        <div v-if="!currentProject">
          <NoContent />
        </div>
      </TransitionFade>
      <SpinnerGeneral v-if="$store.state.Audience.loading" />
      <div class="w-full flex flex-col 2xl:flex-row justify-center lg:items-center 2xl:items-start bg-gray-100 mx-auto overflow-x-hidden" v-if="$store.state.Audience.isSegmentProfileActive">
        <div class="container-xl w-full flex flex-col 2xl:flex-row justify-start lg:items-center 2xl:items-start">
          <!--
          <div class="container-xl bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-9 rounded-lg shadow p-4 w-full lg:w-full 2xl:w-2/12 lg:h-44 2xl:h-80 my-2 mr-2 flex flex-row lg:flex-col justify-center items-center border border-gray-200 ">
            <div class="w-full h-full flex lg:flex-row 2xl:flex-col justify-evenly items-center">
                <div class="w-full flex flex-col px-5">
                    <h2 class="card-title text-base text-gray-500">{{t('audience.segmentName',{},{locale: $store.state.Langs.selected.lang})}}:</h2>
                    <small class="text-gray-500 lg:text-sm 2xl:text-left">{{segmentData.name}}</small>
                </div>
                <hr class="w-3/5 lg:transform lg:rotate-90 2xl:rotate-0">
                <div class="w-full flex flex-col px-5">
                    <h2 class="card-title text-base text-gray-500">{{t('audience.creationData',{},{locale: $store.state.Langs.selected.lang})}}:</h2>
                    <small class="text-gray-500 lg:text-sm 2xl:text-left">{{$moment(segmentData.created_at).format('YYYY-MM-DD HH:mm')}}</small>
                    <small class="text-sm lg:text-sm text-gray-500 2xl:text-left">({{$moment(segmentData.created_at).fromNow(true)}})</small>
                </div>
                <hr class="w-3/5 lg:transform lg:rotate-90 2xl:rotate-0">
                <div class="w-full flex flex-col px-5">
                    <h2 class="card-title text-base text-gray-500">{{t('audience.lastUpdate',{},{locale: $store.state.Langs.selected.lang})}}:</h2>
                    <small class="text-gray-500 lg:text-sm 2xl:text-left">{{$moment(segmentData.updated_at).format('YYYY-MM-DD HH:mm')}}</small>
                    <small class="text-sm lg:text-sm text-gray-500 2xl:text-left">({{$moment(segmentData.updated_at).fromNow(true)}})</small>
                </div>
            </div>
            </div>
          -->
          <div class="container-xl lg:p-0 2xl:p-2 w-full lg:w-full 2xl:w-full flex flex-col">
            <ul class="w-full mb-20" v-if="$store.state.Audience.segmentProfile.length">
              <div class="flex flex-col items-center mb-2 w-full">
                <PaginationSelect :current_page="$store.state.Audience.segmentProfileMeta.current_page" :from="$store.state.Audience.segmentProfileMeta.from" :last_page="$store.state.Audience.segmentProfileMeta.last_page" :path="$store.state.Audience.segmentProfileMeta.path" :per_page="$store.state.Audience.segmentProfileMeta.per_page" :to="$store.state.Audience.segmentProfileMeta.to" :total="$store.state.Audience.segmentProfileMeta.total" @change-page="paginationChange" />
              </div>
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr class="">
                          <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'lastname' ? '-lastname' : 'lastname')">
                              <span>{{ t("audience.lastName", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                              <TableSortDesc v-if="sort == '-lastname'" />
                              <TableSortAsc v-if="sort == 'lastname'" />
                            </div>
                          </th>
                          <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'firstname' ? '-firstname' : 'firstname')">
                              <span>{{ t("audience.name", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                              <TableSortDesc v-if="sort == '-firstname'" />
                              <TableSortAsc v-if="sort == 'firstname'" />
                            </div>
                          </th>
                          <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'email' ? '-email' : 'email')">
                              <span>{{ t("audience.email", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                              <TableSortDesc v-if="sort == '-email'" />
                              <TableSortAsc v-if="sort == 'email'" />
                            </div>
                          </th>
                          <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                            <div class="flex gap-2 cursor-pointer justify-left" @click="sortAndRefresh(sort == 'sales' ? '-sales' : 'sales')">
                              <span>{{ t("audience.purchases", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                              <TableSortDesc v-if="sort == '-sales'" />
                              <TableSortAsc v-if="sort == 'sales'" />
                            </div>
                          </th>
                          <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-widerr">
                            <div class="flex gap-2 cursor-pointer justify-left" @click="sortAndRefresh(sort == 'items_total' ? '-items_total' : 'items_total')">
                              <span>{{ t("audience.product", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                              <TableSortDesc v-if="sort == '-items_total'" />
                              <TableSortAsc v-if="sort == 'items_total'" />
                            </div>
                          </th>
                          <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                            <div class="flex gap-2 cursor-pointer justify-left" @click="sortAndRefresh(sort == 'items_avg' ? '-items_avg' : 'items_avg')">
                              <span>{{ t("audience.average", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                              <TableSortDesc v-if="sort == '-items_avg'" />
                              <TableSortAsc v-if="sort == 'items_avg'" />
                            </div>
                          </th>
                          <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                            <div class="flex gap-2 cursor-pointer justify-left" @click="sortAndRefresh(sort == 'revenue_total' ? '-revenue_total' : 'revenue_total')">
                              <span>{{ t("audience.revenue", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                              <TableSortDesc v-if="sort == '-revenue_total'" />
                              <TableSortAsc v-if="sort == 'revenue_total'" />
                            </div>
                          </th>
                          <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                            <div class="flex gap-2 cursor-pointer justify-left" @click="sortAndRefresh(sort == 'buyer_type' ? '-buyer_type' : 'buyer_type')">
                              <span>{{ t("audience.type", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                              <TableSortDesc v-if="sort == '-buyer_type'" />
                              <TableSortAsc v-if="sort == 'buyer_type'" />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(user, index) in $store.state.Audience.segmentProfile" :key="index" class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-pointer border border-gray-100" @click="showProfileInfo(user.email)">
                          <td class="px-4 py-3 whitespace-nowrap flex flex-row gap-4 items-center">
                            <div class="text-sm text-gray-700 font-semibold">{{ user.lastname }}</div>
                          </td>
                          <td class="px-4 py-3 whitespace-nowrap">
                            <div class="text-sm text-gray-700 font-semibold">{{ user.firstname }}</div>
                          </td>
                          <td class="px-4 py-3 whitespace-nowrap">
                            <div class="text-sm text-gray-700 font-semibold">{{ user.email }}</div>
                          </td>
                          <td class="px-4 py-3 whitespace-nowrap">
                            <div class="text-sm text-gray-700 font-normal">{{ $filters.number(user.sales ? user.sales : 0) }}</div>
                          </td>
                          <td class="px-4 py-3 whitespace-nowrap">
                            <!-- <div>Ingresos</div> -->
                            <div class="text-sm text-gray-700 font-normal">{{ $filters.number(user.items_total ? user.items_total : 0) }}</div>
                          </td>
                          <td class="px-4 py-3 whitespace-nowrap">
                            <!-- <div>Transacciones</div> -->
                            <div class="text-sm text-gray-700 font-normal">{{ $filters.number(user.items_avg ? user.items_avg : 0) }}</div>
                          </td>
                          <td class="px-4 py-3 whitespace-nowrap">
                            <!-- <div>Ticket Promedio</div> -->
                            <div class="text-sm text-gray-700 font-normal">{{ $filters.currency(user.revenue_total ? user.revenue_total : 0) }}</div>
                          </td>
                          <td class="px-4 py-3 whitespace-nowrap">
                            <!-- <div>Ticket Promedio</div> -->
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase" v-if="user.buyer_type == 'offline'">{{ user.buyer_type }}</span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 uppercase" v-if="user.buyer_type == 'online'">{{ user.buyer_type }}</span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 uppercase" v-if="user.buyer_type == 'omnichannel'">{{ user.buyer_type }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-center mt-2 w-full">
                <PaginationSelect :current_page="$store.state.Audience.segmentProfileMeta.current_page" :from="$store.state.Audience.segmentProfileMeta.from" :last_page="$store.state.Audience.segmentProfileMeta.last_page" :path="$store.state.Audience.segmentProfileMeta.path" :per_page="$store.state.Audience.segmentProfileMeta.per_page" :to="$store.state.Audience.segmentProfileMeta.to" :total="$store.state.Audience.segmentProfileMeta.total" @change-page="paginationChange" />
              </div>
            </ul>
            <div v-if="$store.state.Audience.segmentProfile.length === 0" class="text-center block mx-auto my-20">
              <NoResultsFound>
                <div>
                  <p class="text-gray-600 font-semibold text-2xl uppercase">{{ t("audience.noFound", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
                  <!-- <span class="text-md text-gray-400 font-semibold"  v-if="!hasFilter">You can create a segment to target a specific part of your audience</span > -->
                  <span class="text-md text-gray-400" v-if="hasFilter">{{ t("audience.filter", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                </div>
                <!-- <template v-slot:buttons v-if="!hasFilter">
                                    <button @click="createSegentModal" class="btn btn-xs btn-primary">create my first segment</button>
                                </template> -->
              </NoResultsFound>
            </div>
          </div>
          <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col" v-if="error">
            <div class="w-full">
              <router-link :to="{ name: 'Audience', params: { id: $store.state.Projects.idRoute } }" class="btn btn-ghost shadow-none mb-5 hover:bg-gray-200"
                ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-5 h-5 mx-2 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg> Volver
              </router-link>
            </div>
            <div class="text-center block mx-auto my-20">
              <h3 class="text-gray-600 text-2xl font-semibold my-2">{{ errorMessage }}</h3>
              <svg width="96" height="96" fill="none" class="mx-auto mb-6 text-gray-600">
                <path d="M36 28.024A18.05 18.05 0 0025.022 39M59.999 28.024A18.05 18.05 0 0170.975 39" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <ellipse cx="37.5" cy="43.5" rx="4.5" ry="7.5" fill="currentColor"></ellipse>
                <ellipse cx="58.5" cy="43.5" rx="4.5" ry="7.5" fill="currentColor"></ellipse>
                <path d="M24.673 75.42a9.003 9.003 0 008.879 5.563m-8.88-5.562A8.973 8.973 0 0124 72c0-7.97 9-18 9-18s9 10.03 9 18a9 9 0 01-8.448 8.983m-8.88-5.562C16.919 68.817 12 58.983 12 48c0-19.882 16.118-36 36-36s36 16.118 36 36-16.118 36-36 36a35.877 35.877 0 01-14.448-3.017" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M41.997 71.75A14.94 14.94 0 0148 70.5c2.399 0 4.658.56 6.661 1.556a3 3 0 003.999-4.066 12 12 0 00-10.662-6.49 11.955 11.955 0 00-7.974 3.032c1.11 2.37 1.917 4.876 1.972 7.217z" fill="currentColor"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import TransitionFade from "@/components/TransitionFade"
import SpinnerGeneral from "@/components/SpinnerGeneral"
import WrapperSegmentProfiles from "@/components/Profiles/WrapperSegmentProfiles"
import PaginationSelect from "@/components/PaginationSelect"
import Modal_Segment_Audience_profile from "@/components/Profiles/Modal_Segment_Audience_Profiles"
import NoContent from "@/components/Utils/NoContent"
import TableSortDesc from "@/components/Table/TableSortDesc"
import TableSortAsc from "@/components/Table/TableSortAsc"
import NoResultsFound from "@/components/Utils/NoResultsFound"
import axiosClient from "@/config/AxiosClient"

export default {
  name: "Segment Profiles",
  components: {
    WrapperSegmentProfiles,
    TransitionFade,
    SpinnerGeneral,
    PaginationSelect,
    Modal_Segment_Audience_profile,
    NoContent,
    TableSortDesc,
    TableSortAsc,
    NoResultsFound
  },
  data: function () {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      segmentId: this.$route.params.segmentId ? this.$route.params.segmentId : null,
      loading: true,
      sort: "-revenue_total",
      filterKeyword: "",
      default: {
        sort: "-revenue_total",
        limit: 100
      },
      isModalOpen: false,
      isActiveProfileInfo: false,
      profileInfo: null,
      error: false,
      errorMessage: "",
      hasFilter: false,
      segmentData: {}
    }
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  computed: {},
  async created() {
    this.currentProject ? this.getSegmentProfilesInfo() : null
    let segmentData = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/projects/1/segments?filter[id][%3D]=${this.segmentId}`)
    this.segmentData = segmentData.data.data[0]
  },
  methods: {
    sortAndRefresh(sort) {
      this.sort = sort
      this.getSegmentProfilesInfo()
    },

    onFilterWrapper: function (value) {
      if (value == "") this.hasFilter = false
      else if (value) this.hasFilter = true
      this.filterKeyword = value
      this.getSegmentProfilesInfo()
    },
    getSegmentProfilesInfo: function (config = {}) {
      let filter = {}
      if (this.filterKeyword) filter["filter[lastname|firstname|email][like]"] = `%${this.filterKeyword}%`
      filter.sort = this.sort || this.default.sort
      filter.page = config.page || 1
      filter.limit = config.limit || this.default.limit
      let id = this.segmentId
      let currentProject = this.currentProject
      this.$store.dispatch("Audience/showSegmentProfile", { currentProject, id, filter }).then((res) => {
        if (res.status == 404) (this.error = true), (this.errorMessage = res.data.message)
        if (res.status == 403) (this.error = true), (this.errorMessage = res.data.message)
        if (res.status == 200) (this.error = false), (this.errorMessage = "")
      })
    },
    paginationChange: function (page) {
      this.getSegmentProfilesInfo({ page })
    },
    showProfileInfo: function (value) {
      let currentProject = this.currentProject
      this.isActiveProfileInfo = false
      this.profileInfo = []
      this.ModalOpen()
      this.$store.dispatch("Audience/showSegmentProfileWithEmail", { currentProject, value }).then((res) => {
        if (res.status == 404) this.$toast.error("Error, please try again in a few minutes"), this.ModalClose()
        if (res.status == 422) (this.isActiveProfileInfo = false), this.$toast.error("Error, this information cannot be displayed"), this.ModalClose()
        if (res.status == 200) this.isActiveProfileInfo = true
      })
    },
    ModalOpen: function () {
      this.isModalOpen = true
    },
    ModalClose: function () {
      this.isModalOpen = false
    }
  }
}
</script>
