<template>
  <div class="mt-20">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <defs>
          <filter id="ldio-4gk1ux89vw9-filter" x="-100%" y="-100%" width="300%" height="300%" color-interpolation-filters="sRGB">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10"></feGaussianBlur>
            <feComponentTransfer result="cutoff">
              <feFuncA type="linear" slope="125" intercept="-75"></feFuncA>
            </feComponentTransfer>
          </filter>
        </defs>
        <g filter="url(#ldio-4gk1ux89vw9-filter)">
          <circle cx="30" cy="50" r="10" fill="#85a2b6">
            <animate attributeName="r" repeatCount="indefinite" dur="0.819672131147541s" keyTimes="0;0.5;1" values="18;25;18" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
            <animate attributeName="cx" repeatCount="indefinite" dur="0.819672131147541s" keyTimes="0;0.5;1" values="20;27;20" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
          </circle>
          <circle cx="70" cy="50" r="10" fill="#bbcedd">
            <animate attributeName="r" repeatCount="indefinite" dur="0.819672131147541s" keyTimes="0;0.5;1" values="25;18;25" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
            <animate attributeName="cx" repeatCount="indefinite" dur="0.819672131147541s" keyTimes="0;0.5;1" values="66;73;66" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
          </circle>
        </g>
      </svg>
      <span class="font-semibold text-xs text-center block tracking-widest">{{t('content.loading',{},{locale: $store.state.Langs.selected.lang})}}.</span>
    </div>
  </div>
</template>

<script>
import{useI18n} from 'vue-i18n'

export default {
  name: `SpinnerGeneral`,
  setup(){
    const { t, locale } = useI18n();
    return { t , locale }
  },
};
</script>
